<template>
    <div class="uv-page-menu px-1" style="align-self:center">
        <template v-for="(item, index) in pageMenu">
            <v-menu v-if="item.children && item.children.length > 0" :key="index" 
                offset-y open-on-click content-class="elevation-1" tile
                >
                <template #activator="{on, attrs}">
                    <v-btn text tile
                    :color="item.color"
                    v-bind="attrs"
                    v-on="on"
                    class="text-none"
                >
                        <v-icon v-if="item.icon">{{item.icon}}</v-icon>
                        {{item.label}}
                        <v-icon>{{item.appenIcon || icons.expand}}</v-icon>
                    </v-btn>
                </template>
                <uv-page-menu-item :item="item" :click-handler="executeCommand"></uv-page-menu-item>
            </v-menu>
            
            <v-btn :text="!item.btn" tile :color="item.color"
            :key="index"
            class="text-none mr-1"
            v-bind="item.attrs"
            v-on="item.listeners"
            v-else-if="item.visible !== undefined ? item.visible : true"
            
            >
                <v-icon v-if="item.icon">{{item.icon}}</v-icon>
                {{item.label}}
            </v-btn>
        </template>
    </div>
</template>

<script>
import PageMenuItem from "./PageMenuItem";
import {mdiChevronDown} from "@mdi/js";
export default {
    name:"uv-page-menu",
    props:["page-menu"],
    components:{
        "uv-page-menu-item":PageMenuItem
    },
    methods:{
        executeCommand: function(ev, item){
            if (item.command){
                item.command(ev);
            }
        }
    },
    data: function(){
        return {
            icons:{
                expand: mdiChevronDown
            },
        }
    }
}
</script>