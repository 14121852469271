<template>
    <div class="uv-message">
        <v-snackbar
        bottom
        v-model="localMsg.visible"
        :timeout="localMsg.life"
        transition="slide-y-reverse-transition"
        :color="localMsg.severity"
        multi-line
        >

        <v-icon class="mr-2 ml-1">{{icons[localMsg.severity]}}</v-icon>
        <div>
            <h4>{{ localMsg.summary }}</h4>
            <div v-html="localMsg.message"></div>
        </div>
    
        <template v-slot:action="{ attrs }">
        <v-btn
        icon
          v-bind="attrs"
          @click="localMsg.visible = false"
        >
          <v-icon>{{icons.close}}</v-icon>
        </v-btn>
      </template>
        </v-snackbar>
    </div>
</template>

<script>
import { processAlert } from "../plugins/utils.js";
import { mdiAlertOctagon, mdiAlert, mdiInformationVariant, mdiCheckBold, mdiClose } from "@mdi/js";
export default {
    name: "uv-message",
    props: {
        message: {
            type: Object,
            required: true
        },
        life: {
            type: Number
        }
    },
    data: function(){
        return{
            icons:{
                error: mdiAlertOctagon,
                warning: mdiAlert,
                info: mdiInformationVariant,
                success: mdiCheckBold,
                close: mdiClose
            },
        }
    },
    computed: {
        localMsg: function() {
            return processAlert(this.message);
        },
    }
};
</script>

<style scoped lang="sass">
:deep()
    .v-snack--absolute
        z-index:10000


:deep()
    .v-snack__wrapper
        max-width: unset
        width:100%
        .v-snack__content
            display: flex
            padding: 0px !important
            align-items: center
   
</style>
