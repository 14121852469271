<template>
    <validation-provider :rules="rules" v-slot="{errors, required}">
        <v-switch v-bind="$attrs" v-on="$listeners" :error-messages="errors" :label="getLabel(required)" :color="color">
            <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
            <template v-for="(_, name) of $scopedSlots" v-slot:[name]="scope">
                <slot :name="name" v-bind="scope"/>
            </template>
            <template #label v-if="label">
                <div :class="`${color}--text`">
                    {{getLabel(required)}}
                </div>
            </template>
        </v-switch>
    </validation-provider>
</template>
<script>
import Themeable from 'vuetify/lib/mixins/themeable';
export default {
    name:"SwitchBtn",
    mixins:[Themeable],
    model: {
        prop: 'inputValue',
        event: 'change'
    },
    props:{
        rules: {
            type: [Object, String],
            default: ""
        },
        label: {
            type: String,
            default:""
        },
        color:{
            type: String,
            default: 'primary'
        }
    },
    methods:{
        getLabel(required){
            if(this.label){
                if (required){
                    return this.label + " *";
                }
                return this.label;
            }
            return undefined;
        }
    }
}
</script>