<template>
    <div class="page-menu-item">
        <v-list elevation="0" class="py-0" dense>
        
        <template v-for="(child, index) in item.children"> 
        <v-menu v-if="child.children && child.children.length >0" :key="child.label" 
        offset-x 
        open-on-click 
        right 
        tile 
        content-class="elevation-1" 
        nudge-right="1px"
        >
            <template #activator="{on, attrs}">
                <v-list-item
                v-bind="attrs"
                v-on="on"
                >
                    <v-list-item-icon class="mr-2"><v-icon v-if="child.icon">{{child.icon}}</v-icon></v-list-item-icon>
                    <v-list-item-content>{{child.label}}</v-list-item-content>
                    <v-list-item-action><v-icon>{{child.appendIcon || icons.expand}}</v-icon></v-list-item-action>
                </v-list-item>
            </template>
            <v-divider v-if="child.divider" :key="index + '_div'"></v-divider>
            <uv-page-menu-item :item="child" :clickHandler="clickHandler"></uv-page-menu-item>
        </v-menu>
        <v-list-item v-else :key="child.label"
            link
            @click="clickHandler($event, child)"
        >
            <v-list-item-icon class="mr-2"><v-icon v-if="child.icon">{{child.icon}}</v-icon></v-list-item-icon>
            <v-list-item-content>{{child.label}}</v-list-item-content>
        </v-list-item>
        <v-divider v-if="child.divider" :key="index + '_div'"></v-divider>   
        </template>
        
        </v-list>
    </div>
</template>

<script>
import {mdiChevronRight} from "@mdi/js";
export default {
    name: "uv-page-menu-item",
    props: ["item", "click-handler"],
    data: function(){
        return {
            icons:{
                expand: mdiChevronRight
            }
        }
    }
}
</script>
