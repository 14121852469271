<template>
    <validation-provider :rules="rules" :name="name" v-slot="{errors, required}">
        <v-select v-bind="$attrs" v-on="$listeners" :error-messages="errors"
        :color="color" :append-icon="appendIcon">
            <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
            <template v-for="(_, name) of $scopedSlots" v-slot:[name]="scope">
                <slot :name="name" v-bind="scope"/>
            </template>

            <template #label v-if="label">
                <div :class="[errors.length==0 ? `${color}--text` : '']">
                    {{getLabel(required)}}
                </div>
            </template>
        </v-select>
    </validation-provider>
</template>
<script>
export default {
    name:"Select",
    props:{
        rules: {
            type: [Object, String],
            default: ""
        },
        label: {
            type: String
        },
        color:{
            type: String,
            default: "primary"
        },
        name:{
            type: String,
        }
    },
    computed: {
        appendIcon(){
            if (this.$attrs.disabled !== undefined || this.$attrs.readonly !== undefined){
                return "";
            }

            return this.$attrs["append-icon"];
        }
    },
    methods:{
        getLabel(required){
            if(this.label){
                if (required){
                    return this.label + " *";
                }
                return this.label;
            }
            return undefined;
        }
    }
}
</script>